import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../components/bootstrap/Alert';
import Icon from '../components/icon/Icon';
import Page from '../layout/Page/Page';
import PageWrapper from '../layout/PageWrapper/PageWrapper';

const Messenger = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('messenger.title')}>
      <Page>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='pb-5 d-flex justify-content-between'>
              <span>
                <Icon icon='ChatBubble' className='me-2'></Icon>
                {t('messenger.title')}
              </span>
            </h1>

            <Alert color='primary' isLight>
              <Icon icon='AlternateEmail' size={'4x'}></Icon>
              <span className='ms-4'>
                <p className='h5 lh-base text-justify'>
                  {t('messenger.question')} <a href={`mailto:${t('common.unitedEmail')}`}>{t('common.unitedEmail')}</a>
                  {t('messenger.question2')}
                  <a href={`tel:${t('common.unitedPhone').replaceAll(' ', '')}`}>{t('common.unitedPhone')}</a>
                </p>
              </span>
            </Alert>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Messenger;
