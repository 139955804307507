import React, { useContext } from 'react';
import AuthContext from '../../../contexts/authContext';
import { parseJSON } from '../../../hooks';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { Client } from '../../profile/IClient';

const ClientHeader = () => {
  const { client } = useContext(AuthContext);
  const clientObj: Client = parseJSON(client);

  return (
    <Header>
      <HeaderLeft>
        <div className='d-flex name-header align-items-center'>
          <span className='display-6 fw-bold me-3'>{`${clientObj?.user_title ? `${clientObj?.user_title} ` : ''}${
            clientObj?.user_first_name
          } ${clientObj?.user_second_name}${clientObj?.group_name ? ` - ${clientObj?.group_name}` : ''}`}</span>
          <span className='border border-primary border-2 text-primary fw-bold px-3 py-2 rounded nowrap'>
            {clientObj?.stage_name}
          </span>
        </div>
      </HeaderLeft>
      <CommonHeaderRight />
    </Header>
  );
};

export default ClientHeader;
