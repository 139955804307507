import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { APIUtils } from '../api';
import Button from '../components/bootstrap/Button';
import Card, { CardBody } from '../components/bootstrap/Card';
import Spinner from '../components/bootstrap/Spinner';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Select from '../components/bootstrap/forms/Select';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import { useAPICall } from '../hooks';
import Page from '../layout/Page/Page';
import PageWrapper from '../layout/PageWrapper/PageWrapper';

const Book = () => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState<boolean>(false);
  const sendCall = useAPICall();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({ stage: Yup.string().required() }),
    initialValues: {
      stage: 'stage 1'
    },
    validateOnChange: true,
    onSubmit: (values) => {
      const { stage } = values;
      if (stage) {
        setIsSending(true);
        if (!isSending) {
          sendCall.run(
            APIUtils,
            'POST',
            (saveResult) => {
              if (saveResult?.success) {
                showNotification(t('book.orderbookSuccess.title'), t('book.orderbookSuccess.msg'), 'success');
              }
              setIsSending(false);
            },
            {
              action: 'orderBook',
              stage
            }
          );

          setIsSending(false);
        }
      }
    }
  });
  let i = 1;
  let stages = [];
  while (i <= 12) {
    stages.push({ value: `stage ${i}`, text: `stage ${i}` });
    i++;
  }

  return (
    <PageWrapper title={t('book.title')}>
      <Page>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='pb-5 d-flex justify-content-between'>
              <span>
                <Icon icon='MenuBook' className='me-2'></Icon>
                {t('book.title')}
              </span>
            </h1>

            <Card className='shadow-3d-primary'>
              <CardBody>
                <div className='d-flex flex-column align-items-center'>
                  <p className='h4 text-center'>{t('book.infotitle')}</p>
                  <p className='h5 text-center'>{t('book.infosubtitle')}</p>
                  <img src='books.png' alt='Callan Books' className='img-fluid' />

                  <FormGroup tag={'section'} id={'new-lesson'} size={'lg'} className='d-flex flex-column gap-4'>
                    <div className='row'>
                      <div className='mt-4 mt-md-0 col-xs-12'>
                        <div className='h4'>{t('book.ordertext')}</div>
                        <Select
                          id='stage'
                          name='stage'
                          ariaLabel='stage'
                          list={stages}
                          size='lg'
                          value={formik.values.stage}
                          onChange={formik.handleChange}
                        ></Select>
                      </div>
                    </div>

                    <div className='d-flex flex-row-reverse'>
                      <Button
                        color='primary'
                        size={'lg'}
                        icon='send'
                        iconBefore={false}
                        isDisable={!formik.isValid}
                        onClick={formik.handleSubmit}
                      >
                        {isSending ? <Spinner isSmall inButton isGrow /> : t('lessons.new.form.send')}
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Book;
