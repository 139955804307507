import React from 'react';

const LessonOnlineBadge = () => {
  return (
    <span
      className={`border border-finished h6 border-2 ms-3 text-finished fw-bold px-2 py-1 rounded nowrap valign-middle`}
    >
      ONLINE
    </span>
  );
};

export default LessonOnlineBadge;
