import axios from 'axios';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import showNotification from './components/extras/showNotification';
import AuthContext from './contexts/authContext';
import { APIUtils } from './api';

export const useClientPremium = (): boolean => {
  const { isPremium, token, setIsPremium } = useContext(AuthContext);
  const call = useAPICall();
  if (isPremium === null && token) {
    call.run(
      APIUtils,
      'POST',
      (premium: any) => {
        setIsPremium(Boolean(+premium.isPremium));
        return premium;
      },
      { action: 'isPremium' }
    );
  } else {
    return isPremium === true;
  }
  return false;
};

export const useTokenValid = (): Boolean => {
  const { setToken, setClient, token } = useContext(AuthContext);

  if (token) {
    const tokenSpl = token.split('.');
    if (tokenSpl.length > 1) {
      const tokenDataEncoded = tokenSpl[1];
      const tokenDataDecoded = window.atob(tokenDataEncoded);
      if (tokenDataDecoded) {
        const tokenDataObj = parseJSON(tokenDataDecoded);
        if (tokenDataObj?.exp && tokenDataObj?.exp < new Date().getTime()) {
          showNotification('Session vypršala', 'Pre pokračovanie je potrebné sa opätovne prihlásiť', 'warning');
          setToken('');
          setClient('');
          return false;
        } else {
          return true;
        }
      }
    }
  }
  return false;
};

export const parseJSON = (jsonString: string) => {
  let result = null;
  if (jsonString) {
    try {
      result = JSON.parse(jsonString);
    } catch (e) {
      console.error('PARSE_ERROR', e);
    }
    return result;
  }
};

export const useAPICall = () => {
  const { t } = useTranslation('errors');
  const { token } = useContext(AuthContext);

  const run = useCallback(
    async (
      apiPath: string,
      method: 'POST' | 'GET' = 'POST',
      onSuccess?: (response: any) => void,
      body?: any,
      noToken: boolean = false,
      onError?: (err: any) => void
    ) => {
      if (!noToken && !token) {
        return;
      }
      try {
        const config =
          !noToken && token
            ? {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            : undefined;
        const response = method === 'POST' ? await axios.post(apiPath, body, config) : await axios.get(apiPath, config);
        const data = response.data;
        if (data?.error?.code) {
          showNotification('Chyba', t(data.error.code), 'danger');
        } else {
          if (onSuccess) {
            onSuccess(data);
          }
        }
      } catch (err: any) {
        showNotification('Chyba', t('GENERIC'), 'danger');
        if (onError) {
          onError(err);
        }
      }
    },
    [t, token]
  );
  return { run };
};
