import classNames from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import packageInfo from '../../../package.json';
import { APILogin } from '../../api';
import Logo from '../../components/Logo';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import AuthContext from '../../contexts/authContext';
import { parseJSON, useAPICall, useTokenValid } from '../../hooks';
import useDarkMode from '../../hooks/useDarkMode';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { menuPaths } from '../../menu';
import { Client } from '../profile/IClient';

interface ILoginHeaderProps {
  isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='text-center h1 fw-bold mt-5'>{t('login.welcomeTitle')}</div>
      <div className='text-center h4 text-muted mb-5'>{t('login.welcomeSubTitle')}</div>
    </>
  );
};

const Login: FC<any> = () => {
  const { setToken, setClient, client, setIsPremium } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clientObj: Client = parseJSON(client);

  const { darkModeStatus } = useDarkMode();

  const navigate = useNavigate();
  const handleOnClick = useCallback(
    (c: Client) => {
      navigate(c?.group_id ? `/${menuPaths.lessons}` : `/${menuPaths.upcoming}`);
    },
    [navigate]
  );

  const loginCall = useAPICall();
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: '',
      loginPassword: ''
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = 'Povinné';
      }

      if (!values.loginPassword) {
        errors.loginPassword = 'Povinné';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      setIsLoading(true);
      if (!isLoading) {
        loginCall.run(
          APILogin,
          'POST',
          (loginData) => {
            setToken(loginData.token ?? '');
            setClient(loginData.client ? JSON.stringify(loginData.client) : '');
            setIsPremium(Boolean(+loginData.client?.premium));
            handleOnClick(loginData.client);
            setIsLoading(false);
          },
          {
            login: values.loginUsername,
            password: values.loginPassword
          },
          true
        );

        setIsLoading(false);
      }
    }
  });

  if (useTokenValid()) {
    handleOnClick(clientObj);
    return null;
  }

  return (
    <PageWrapper
      isProtected={false}
      title={t('login.title')}
      className={classNames({
        'bg-light': !darkModeStatus,
        'bg-dark': darkModeStatus
      })}
    >
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center my-5'>
                  <Logo width={300} />
                </div>

                <LoginHeader />

                <form className='row g-4'>
                  {
                    <>
                      <div className='col-12'>
                        <FormGroup id='loginUsername' isFloating label={t('login.loginInput')}>
                          <Input
                            autoComplete='username'
                            value={formik.values.loginUsername}
                            isTouched={formik.touched.loginUsername}
                            invalidFeedback={formik.errors.loginUsername}
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => {
                              formik.setErrors({});
                            }}
                          />
                        </FormGroup>
                        <FormGroup id='loginPassword' isFloating label={t('login.passwordInput')}>
                          <Input
                            type='password'
                            autoComplete='current-password'
                            value={formik.values.loginPassword}
                            isTouched={formik.touched.loginPassword}
                            invalidFeedback={formik.errors.loginPassword}
                            validFeedback={t('login.success')}
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={(event) => (event.key === 'Enter' ? formik.handleSubmit() : null)}
                          />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        {
                          <Button color='primary' className='w-100 py-3' onClick={formik.handleSubmit}>
                            {isLoading ? <Spinner isSmall inButton isGrow /> : t('login.submitButton')}
                          </Button>
                        }
                      </div>
                    </>
                  }
                </form>
              </CardBody>
            </Card>
            <div className='text-center'>
              <p
                className={classNames('text-decoration-none', {
                  'link-dark': !darkModeStatus,
                  'link-light': darkModeStatus
                })}
              >
                <a href='https://united-school.sk' target='_blank' rel='noreferrer' className='text-decoration-none'>
                  UNITED! Jazyková škola
                </a>
              </p>
              <p
                className={classNames({
                  'link-dark': !darkModeStatus,
                  'link-light': darkModeStatus
                })}
              >
                <span className='version'>
                  {packageInfo?.version} | {process.env.REACT_APP_SITE_ROLE}
                </span>
              </p>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool
};
Login.defaultProps = {
  isSignUp: false
};

export default Login;
