import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { APILessonRemove, APILessons } from '../../api';
import Accordion, { AccordionItem } from '../../components/bootstrap/Accordion';
import Alert from '../../components/bootstrap/Alert';
import Button from '../../components/bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import { sortGroupLessons } from '../../helpers/helpers';
import { useAPICall } from '../../hooks';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { Lesson, LessonStatus, LessonsResp } from './Ilesson';
import LessonOnlineBadge from './LessonOnlineBadge';

const CancelLesson = () => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<Lesson[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lessonToRemove, setLessonToRemove] = useState<Lesson>();

  const apiCallHook = useAPICall();

  const loadLessons = () => {
    setIsLoading(true);
    if (!isLoading) {
      apiCallHook.run(
        APILessons,
        'POST',
        (l: LessonsResp) => {
          setIsLoading(false);
          setLessons(l.lessons);
        },
        { finished: false },
        undefined,
        () => setIsLoading(false)
      );
    }
  };

  useEffectOnce(loadLessons);

  const cancelLesson = (lessonId: number) => {
    setIsLoading(true);
    if (!isLoading) {
      apiCallHook.run(
        APILessonRemove,
        'POST',
        (removeResult) => {
          setIsLoading(false);
          if (removeResult?.success) {
            loadLessons();
            setLessonToRemove(undefined);
            if (removeResult?.deleted) {
              showNotification(
                t('lessons.cancelLesson.successRemoved.title'),
                t('lessons.cancelLesson.successRemoved.msg'),
                'success'
              );
            } else {
              showNotification(
                t('lessons.cancelLesson.successRequest.title'),
                t('lessons.cancelLesson.successRequest.msg'),
                'success'
              );
            }
          }
        },
        { lessonId },
        undefined,
        () => setIsLoading(false)
      );
    }
  };

  // Možnosť zrušiť hodinu len pre neodučené, nové žiadosti alebo bez lektora
  // a v prípade ak nie je viac ako 19hod. predchádzajúceho dňa, kedy je hodina
  const showCancelLessonButton = (lesson: Lesson): boolean => {
    const limit = moment(lesson.lessontime).subtract(1, 'day').hour(19).toDate();
    return (
      [LessonStatus.NotFinished, LessonStatus.NewRequest, LessonStatus.WithoutLector].includes(lesson.status) &&
      moment().toDate() < limit
    );
  };

  const sortedGroupLessons: any = sortGroupLessons(lessons);

  return (
    <PageWrapper title={t('menu.cancel')}>
      <Page>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='pb-3 d-flex justify-content-between'>
              <span>
                <Icon icon='cancel' className='me-2'></Icon>
                {t('menu.cancel')}
              </span>
            </h1>

            <Alert color='info' isLight className='mb-5'>
              <Icon icon='info' size={'2x'}></Icon>
              <div className='ms-4 lh-base text-justify'>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('lessons.cancelLesson.infotitle', { interpolation: { escapeValue: false } })
                  }}
                ></span>
                <span className='icon-canceledwithpayment ms-2'>
                  <Icon icon='AccessTime'></Icon>
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('lessons.cancelLesson.infotitle2', { interpolation: { escapeValue: false } })
                  }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('lessons.cancelLesson.infosubtitle', { interpolation: { escapeValue: false } })
                  }}
                ></span>
                <span className='icon-canceledwithpayment ms-2'>
                  <Icon icon='AccessTime'></Icon>
                </span>
              </div>
            </Alert>

            {isLoading ? (
              <div className='text-center'>
                <div className='spinner-border text-primary' role='status'></div>
              </div>
            ) : null}

            {Object.keys(sortedGroupLessons).length > 0 && (
              <>
                <h3 className='pb-1 d-flex justify-content-between'>
                  <span>
                    <Icon icon='AccessTime' className='me-2'></Icon>
                    {t('upcoming.upcomingLessons')}
                  </span>
                </h3>
                <Accordion
                  id={'lesson-stages'}
                  shadow={'lg'}
                  isFlush={false}
                  color={'primary'}
                  activeItemId={Object.keys(sortedGroupLessons)[0]}
                >
                  {Object.keys(sortedGroupLessons)?.map((key: string, idx) => {
                    const monthOfLessons: Lesson[] = sortedGroupLessons[key];
                    const title = `${new Date(new Date().setMonth(+key.split('-')[1] - 1)).toLocaleString('sk', {
                      month: 'long'
                    })} ${key.split('-')[0]}`;
                    return (
                      <AccordionItem key={idx} id={key} title={title} headerTag={'h1'}>
                        {monthOfLessons?.map((lesson: Lesson, lidx) => (
                          <Card className={`shadow-3d-${lesson.status_class}`} shadow={'none'} key={lidx}>
                            <CardHeader>
                              <CardLabel icon='AccessTime' className={`full-width icon-${lesson.status_class}`}>
                                <CardTitle
                                  tag='h5'
                                  className='h5 d-flex align-items-center justify-content-between'
                                  style={{ lineHeight: '1.5em' }}
                                >
                                  <div>
                                    <span>{lesson.datetime}</span>
                                    <span
                                      className={`border border-${lesson.status_class} h6 border-2 ms-3 text-${lesson.status_class} fw-bold px-2 py-1 rounded nowrap`}
                                    >
                                      {t(`lessons.status.${lesson.status}`)}
                                    </span>
                                    {Boolean(lesson?.online) && <LessonOnlineBadge />}
                                  </div>
                                  <div>
                                    {showCancelLessonButton(lesson) && (
                                      <Button
                                        color='danger'
                                        className='ms-3'
                                        isOutline
                                        onClick={() => setLessonToRemove(lesson)}
                                      >
                                        {t('lessons.cancelLesson.action')}
                                      </Button>
                                    )}
                                  </div>
                                </CardTitle>
                              </CardLabel>
                            </CardHeader>
                          </Card>
                        ))}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </>
            )}
          </div>
        </div>
      </Page>
      <Modal
        id='removeModal'
        isOpen={Boolean(lessonToRemove)}
        setIsOpen={setLessonToRemove}
        isStaticBackdrop={true}
        isCentered={true}
      >
        <ModalHeader setIsOpen={setLessonToRemove}>
          <ModalTitle id={'modalTitle'}>{t('lessons.cancelLesson.title')}</ModalTitle>
        </ModalHeader>
        <ModalBody>{t('lessons.cancelLesson.msg', { time: lessonToRemove?.datetime })}</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => (lessonToRemove?.id ? cancelLesson(lessonToRemove.id) : null)}>
            {t('common.yes')}
          </Button>
          <Button onClick={() => setLessonToRemove(undefined)}>{t('common.no')}</Button>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  );
};

export default CancelLesson;
