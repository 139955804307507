import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import Login from '../../pages/auth/Login';

const PAGE_404 = lazy(() => import('../../pages/auth/Page404'));
const ContentRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />

      {contents.map((page) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route path={page.path} key={page.path} {...page} />
      ))}
      <Route path='*' element={<PAGE_404 />} />
    </Routes>
  );
};

export default ContentRoutes;
