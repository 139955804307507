import classNames from 'classnames';
import React, { useContext } from 'react';
import Button from '../../components/bootstrap/Button';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import AuthContext from '../../contexts/authContext';
import { parseJSON } from '../../hooks';
import useDarkMode from '../../hooks/useDarkMode';
import { Client } from '../../pages/profile/IClient';

const User = () => {
  const { client } = useContext(AuthContext);

  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const clientObj: Client = client ? parseJSON(client) : {};

  return (
    <>
      <div
        className={classNames('user')}
        role='presentation'
        // onClick={() => setCollapseStatus(!collapseStatus)}
      >
        <div className='user-info'>
          <div className='user-name d-flex align-items-center'>
            {`${clientObj.user_title ? `${clientObj.user_title} ` : ''}${clientObj.user_first_name} ${
              clientObj.user_second_name
            }`}
          </div>
          {clientObj?.group_name && <div className='user-name d-flex align-items-center'>{clientObj.group_name}</div>}
          <div className='user-sub-title'>{clientObj.user_login}</div>
        </div>
      </div>

      {/* <div className='navigation'>
        <div
          role='presentation'
          className='navigation-item cursor-pointer'
          onClick={() => {
            if (setToken) {
              setToken('');
            }
            if (setClient) {
              setClient('');
            }
            navigate(`../${menuPaths.login}`);
          }}
        >
          <span className='navigation-link navigation-link-pill'>
            <span className='navigation-link-info'>
              <Icon icon='Logout' className='navigation-icon' />
              <span className='navigation-text'>{t('menu:Logout') as ReactNode}</span>
            </span>
          </span>
        </div>
      </div> */}

      <DropdownMenu>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label='Toggle fullscreen'
          >
            {darkModeStatus ? 'Dark Mode' : 'Light Mode'}
          </Button>
        </DropdownItem>
      </DropdownMenu>

      {/* <Collapse isOpen={collapseStatus} className='user-menu'>
        <nav aria-label='aside-bottom-user-menu'>
          <div className='navigation'>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() => {
                setDarkModeStatus(!darkModeStatus);
                handleItem();
              }}
            >
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon
                    icon={!darkModeStatus ? 'DarkMode' : 'LightMode'}
                    color={!darkModeStatus ? 'info' : 'warning'}
                    className='navigation-icon'
                  />
                  <span className='navigation-text'>
                    {!darkModeStatus ? (t('menu:DarkMode') as ReactNode) : (t('menu:LightMode') as ReactNode)}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </nav>
        <NavigationLine />
        <nav aria-label='aside-bottom-user-menu-2'></nav>
      </Collapse> */}
    </>
  );
};

export default User;
