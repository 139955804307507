import PropTypes from 'prop-types';
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { APIRenewToken } from '../api';
import { useAPICall } from '../hooks';

export interface IAuthContextProps {
  token: string;
  setToken(...args: unknown[]): unknown;
  client: string;
  setClient(...args: unknown[]): unknown;
  isPremium: boolean | null;
  setIsPremium(...args: unknown[]): unknown;
  openedMonthIdx: number | null;
  setOpenedMonthIdx(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string>(sessionStorage.getItem('auth-token') || '');
  const [client, setClient] = useState<string>(sessionStorage.getItem('united_client') || '');
  const [isPremium, setIsPremium] = useState<boolean | null>(null);
  const [openedMonthIdx, setOpenedMonthIdx] = useState<number | null>(null);

  const renewTokenCall = useAPICall();

  useEffect(() => {
    sessionStorage.setItem('auth-token', token);
    //Start timer to renew token in 55 minutes (session is 60 minutes)
    if (token) {
      const timer = setTimeout(() => {
        renewTokenCall.run(APIRenewToken, 'GET', (newTokenData) => setToken(newTokenData.token), undefined, false);
      }, 3_300_000);
      return () => clearTimeout(timer);
    }
  }, [token, renewTokenCall]);

  useEffect(() => {
    sessionStorage.setItem('united_client', client);
  }, [client]);

  useEffect(() => {
    sessionStorage.setItem('opened_month_idx', openedMonthIdx !== null ? openedMonthIdx.toString() : '');
  }, [openedMonthIdx]);

  const value = useMemo(
    () => ({
      token,
      setToken,
      client,
      setClient,
      isPremium,
      setIsPremium,
      openedMonthIdx,
      setOpenedMonthIdx
    }),
    [token, client, isPremium, openedMonthIdx]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
