import React, { FC } from 'react';
import PropTypes from 'prop-types';
import useDarkMode from '../hooks/useDarkMode';

interface ILogoProps {
  width?: number;
  height?: number;
  forceLight?: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, forceLight = false }) => {
  const { darkModeStatus } = useDarkMode();
  const path = darkModeStatus || forceLight ? '/logo-light.svg' : '/logo.svg';
  return (
    <img
      src={path}
      alt='logo'
      width={height !== 854 && !!height ? height * (2155 / 854) : width}
      height={width !== 2155 && !!width ? width * (854 / 2155) : height}
    />
  );
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};
Logo.defaultProps = {
  width: 2155,
  height: 854
};

export default Logo;
