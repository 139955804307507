import PropTypes from 'prop-types';
import React, { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import { HeaderRight } from '../../../layout/Header/Header';

interface ICommonHeaderRightProps {
  beforeChildren?: ReactNode;
  afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
  const { darkModeStatus } = useDarkMode();

  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg'
  };

  const { t } = useTranslation();

  return (
    <HeaderRight>
      <div className='row g-3'>
        {beforeChildren}

        {/* Dark Mode */}
        {/* <div className='col-auto'>
          <Popovers trigger='hover' desc={t('header.themeToggle')}>
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...styledBtn}
              onClick={() => setDarkModeStatus(!darkModeStatus)}
              className='btn-only-icon'
              data-tour='dark-mode'
            >
              <Icon
                icon={!darkModeStatus ? 'DarkMode' : 'LightMode'}
                color={!darkModeStatus ? 'info' : 'warning'}
                className='btn-icon'
              />
            </Button>
          </Popovers>
        </div> */}

        {/*	Full Screen */}
        <div className='col-auto'>
          <Popovers trigger='hover' desc={t('header.fullscreen')}>
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...styledBtn}
              icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
              onClick={() => setFullScreenStatus(!fullScreenStatus)}
            />
          </Popovers>
        </div>

        {afterChildren}
      </div>
    </HeaderRight>
  );
};
CommonHeaderRight.propTypes = {
  beforeChildren: PropTypes.node,
  afterChildren: PropTypes.node
};
CommonHeaderRight.defaultProps = {
  beforeChildren: null,
  afterChildren: null
};

export default CommonHeaderRight;
