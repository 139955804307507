import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/authContext';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { unitedMenu } from '../../../menu';
import { parseJSON } from '../../../hooks';
import { Client } from '../../profile/IClient';

const DefaultAside = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const { client } = useContext(AuthContext);
  const clientObj: Client = parseJSON(client);

  const { t } = useTranslation();
  return (
    <Aside>
      <AsideHead>
        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
      </AsideHead>
      <AsideBody>
        <Navigation menu={unitedMenu(t, clientObj)} id='aside-dashboard' />
      </AsideBody>
      <AsideFoot>
        <nav aria-label='aside-bottom-menu'>
          <div className='navigation'></div>
        </nav>
        <User />
      </AsideFoot>
    </Aside>
  );
};

export default DefaultAside;
