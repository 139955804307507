import { Client } from './pages/profile/IClient';

export const menuPaths = {
  upcoming: 'upcoming',
  cancel: 'cancel',
  lesson: 'lesson',
  login: 'login',
  book: 'book',
  logout: 'logout',
  lessons: 'my-lessons',
  messenger: 'messenger',
  profile: 'profile',
  changepass: 'changepass'
};

export const unitedMenu = (t: any, clientObj: Client) => ({
  profile: {
    id: 'profile',
    text: t('menu.profile'),
    path: menuPaths.profile,
    icon: 'AccountBox',
    subMenu: null
  },
  upcoming: {
    id: 'upcoming',
    text: t('menu.upcoming'),
    path: menuPaths.upcoming,
    isHidden: !!clientObj?.group_id,
    icon: 'moreTime',
    subMenu: null
  },
  cancel: {
    id: 'cancel',
    text: t('menu.cancel'),
    path: menuPaths.cancel,
    isHidden: !!clientObj?.group_id,
    icon: 'cancel',
    subMenu: null
  },
  lessons: {
    id: 'lessons',
    text: t(!clientObj?.group_id ? 'menu.lessons' : 'menu.myLessons'),
    path: menuPaths.lessons,
    icon: 'AccessTime',
    subMenu: null
  },
  book: {
    id: 'book',
    text: t('menu.book'),
    path: menuPaths.book,
    icon: 'MenuBook',
    subMenu: null
  },
  messenger: {
    id: 'messenger',
    text: t('menu.messenger'),
    path: menuPaths.messenger,
    icon: 'ChatBubble',
    subMenu: null
  },
  logout: {
    id: 'logout',
    text: t('menu.logout'),
    path: menuPaths.logout,
    icon: 'Logout',
    subMenu: null
  }
});
