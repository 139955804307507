import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import COLORS from '../common/data/enumColors';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import ThemeContext from '../contexts/themeContext';
import { getOS } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import Portal from '../layout/Portal/Portal';
import Wrapper from '../layout/Wrapper/Wrapper';

const App = () => {
  getOS();

  /**
   * Dark Mode
   */
  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    purple: COLORS.PURPLE.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute('theme', 'dark');
    }
    return () => {
      document.documentElement.removeAttribute('theme');
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  // @ts-ignore
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false)
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESIGN === 'true') {
      document.body.classList.add('modern-design');
    } else {
      document.body.classList.remove('modern-design');
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider components={{ ToastContainer, Toast }}>
        <div
          ref={ref}
          className='app'
          style={{
            backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
            zIndex: fullScreenStatus ? 1 : undefined,
            overflow: fullScreenStatus ? 'scroll' : undefined
          }}
        >
          <AsideRoutes />
          <Wrapper />
        </div>
        <Portal id='portal-notification'>
          <ReactNotifications />
        </Portal>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
