import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import packageInfo from '../../../../package.json';

const DefaultFooter = () => {
  const { darkModeStatus } = useDarkMode();

  return (
    <Footer>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <span className='fw-light'>
              © {new Date().getFullYear()} - {packageInfo.version} | {process.env.REACT_APP_SITE_ROLE}
            </span>
          </div>
          <div className='col-auto'>
            <a
              href='https://united-school.sk'
              className={classNames('text-decoration-none', {
                'link-dark': !darkModeStatus,
                'link-light': darkModeStatus
              })}
            >
              <small className='fw-bold'>UNITED! Jazyková škola</small>
            </a>
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default DefaultFooter;
