import React from 'react';
import { RouteProps } from 'react-router-dom';
import { menuPaths } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
  {
    path: `/${menuPaths.logout}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.upcoming}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.cancel}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.messenger}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.book}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.profile}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.profile}/${menuPaths.changepass}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.lessons}`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.lesson}/new`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.lesson}/:id/rating`,
    element: <DefaultAside />
  },
  {
    path: `/${menuPaths.lesson}/:id`,
    element: <DefaultAside />
  },
  { path: '*', element: null }
];

export default asides;
