export interface LessonDetailResp {
  lesson: LessonDetail;
}

export interface GrammarResp {
  grammar: Grammar[];
  video: Video[];
  total_grammar_count: number;
  total_video_count: number;
}

export interface Video {
  id: number;
}

export interface Grammar {
  id: number;
  name: string;
  image?: string;
  mime: string;
  page: number;
}

export interface WordsResp {
  words: Word[];
  total_count: number;
}

export interface Word {
  id: number;
  word: string;
  translation?: string;
  page: string;
}

export interface QuestionResp {
  questions: Question[];
  total_count: number;
}

export interface Question {
  id: number;
  question: string;
  question_locale?: string;
  question_hint?: string;
  answer: string;
  answer_locale?: string;
  page: string;
  stage: string;
}

export enum WordType {
  'REPETITION' = 'repetition',
  'NEWCURRICULUM' = 'newcur'
}

export interface LessonDetail {
  dictate: string;
  lector_nickname: string;
  lesson_time: string;
  newcurriculum: string;
  place_name: string;
  reading: string;
  repetition: string;
  sheetnote_client: string;
  theme: string;
  word_name: string;
  online: boolean;
  word_page: number;
  stage_identifier: string;
}

export interface LessonsResp {
  lessons: Lesson[];
  total_count: number;
}

export interface Lesson {
  id: number;
  status: number;
  status_class: string;
  datetime: string;
  online: boolean;
  lessontime: string;
  place: string;
}

export enum LessonStatus {
  'NotFinished' = 0,
  'Finished' = 1,
  'Canceled' = 2,
  'CanceledWithPayment' = 3,
  'WithoutLector' = 4,
  'NewRequest' = 5,
  'CancelRequest' = 6
}
