import React from 'react';
import { RouteProps } from 'react-router-dom';
import { menuPaths } from '../menu';
import ClientHeader from '../pages/_layout/_headers/ClientHeader';

const headers: RouteProps[] = [
  { path: `/${menuPaths.login}`, element: null },
  {
    path: `/${menuPaths.upcoming}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.cancel}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.messenger}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.book}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.lessons}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.profile}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.profile}/${menuPaths.changepass}`,
    element: <ClientHeader />
  },
  {
    path: `/${menuPaths.lesson}/*`,
    element: <ClientHeader />
  },

  {
    path: `*`,
    element: null
  }
];

export default headers;
