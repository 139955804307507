import { FC, useContext } from 'react';
import AuthContext from '../../contexts/authContext';
import { menuPaths } from '../../menu';
import { useNavigate } from 'react-router-dom';

const Logout: FC<any> = () => {
  const { setToken, setClient } = useContext(AuthContext);
  const navigate = useNavigate();
  if (setClient) {
    setClient('');
  }
  if (setToken) {
    setToken('');
  }
  navigate(`../${menuPaths.login}`);
  return null;
};

export default Logout;
