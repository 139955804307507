import { BASE_URL } from './config';

export const APILogin = `${BASE_URL}login.php`;
export const APIRenewToken = `${BASE_URL}renew-token.php`;
export const APILessons = `${BASE_URL}lessons.php`;
export const APILessonDetail = `${BASE_URL}lesson-detail.php`;
export const APILessonNew = `${BASE_URL}lesson-new.php`;
export const APILessonRemove = `${BASE_URL}lesson-remove.php`;
export const APIWords = `${BASE_URL}words.php`;
export const APIUtils = `${BASE_URL}utils.php`;
export const APIGrammar = `${BASE_URL}grammar.php`;
export const APIQuestions = `${BASE_URL}questions.php`;
export const APIStageProgress = `${BASE_URL}stage-progress.php`;

export const APIServeVideo = (videoId: string, token: string) => {
  return `${BASE_URL}servevideo.php?videoId=${videoId}&token=${token}`;
};
