import React from 'react';
import { RouteProps } from 'react-router-dom';
import { menuPaths } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
  {
    path: `/${menuPaths.logout}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.upcoming}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.cancel}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.messenger}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.book}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.profile}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.profile}/${menuPaths.changepass}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.lessons}`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.lesson}/new`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.lesson}/:id/rating`,
    element: <DefaultFooter />
  },
  {
    path: `/${menuPaths.lesson}/:id`,
    element: <DefaultFooter />
  },
  { path: '*', element: null }
];

export default footers;
