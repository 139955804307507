import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { menuPaths } from '../menu';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import CancelLesson from '../pages/lesson/CancelLesson';
import Messenger from '../pages/Messenger';
import Upcoming from '../pages/lesson/Upcoming';
import Book from '../pages/Book';

const PROFILE = {
  MY_PROFILE: lazy(() => import('../pages/profile/Profile')),
  CHANGE_PASS: lazy(() => import('../pages/profile/ChangePass')),
  MY_LESSONS: lazy(() => import('../pages/lesson/MyLessons')),
  LESSON_NEW: lazy(() => import('../pages/lesson/LessonNew')),
  LESSON_DETAIL: lazy(() => import('../pages/lesson/Lesson')),
  LESSON_RATING: lazy(() => import('../pages/lesson/LessonRating'))
};

const presentation: RouteProps[] = [
  {
    path: `/${menuPaths.login}`,
    element: <Login />
  },
  {
    path: `/${menuPaths.logout}`,
    element: <Logout />
  },
  {
    path: `/${menuPaths.upcoming}`,
    element: <Upcoming />
  },
  {
    path: `/${menuPaths.cancel}`,
    element: <CancelLesson />
  },
  {
    path: `/${menuPaths.messenger}`,
    element: <Messenger />
  },
  {
    path: `/${menuPaths.book}`,
    element: <Book />
  },
  {
    path: `/${menuPaths.profile}`,
    element: <PROFILE.MY_PROFILE />
  },
  {
    path: `/${menuPaths.profile}/${menuPaths.changepass}`,
    element: <PROFILE.CHANGE_PASS />
  },
  {
    path: `/${menuPaths.lessons}`,
    element: <PROFILE.MY_LESSONS />
  },
  {
    path: `/${menuPaths.lesson}/new`,
    element: <PROFILE.LESSON_NEW />
  },
  {
    path: `/${menuPaths.lesson}/:id/rating`,
    element: <PROFILE.LESSON_RATING />
  },
  {
    path: `/${menuPaths.lesson}/:id`,
    element: <PROFILE.LESSON_DETAIL />
  }
];
const contents = [...presentation];

export default contents;
