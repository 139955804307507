import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import Tooltips from '../../components/bootstrap/Tooltips';
import Icon from '../../components/icon/Icon';

interface IBrandProps {
  asideStatus: boolean;
  setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
  const { t } = useTranslation();
  return (
    <div className='brand'>
      <div className='brand-logo'>
        <h1 className='brand-title '>
          <Link to='/profile' aria-label='Logo'>
            <Logo height={50} forceLight={true} />
          </Link>
        </h1>
      </div>
      <Tooltips title={t('common.menu')}>
        <button
          type='button'
          className='btn brand-aside-toggle'
          aria-label='Toggle Aside'
          onClick={() => setAsideStatus(!asideStatus)}
        >
          <Icon icon='Menu' className='brand-aside-toggle-close' />
          <Icon icon='Menu' className='brand-aside-toggle-open' />
        </button>
      </Tooltips>
    </div>
  );
};
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired
};

export default Brand;
