import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { APILessons } from '../../api';
import Accordion, { AccordionItem } from '../../components/bootstrap/Accordion';
import Button from '../../components/bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Icon from '../../components/icon/Icon';
import { sortGroupLessons } from '../../helpers/helpers';
import { useAPICall } from '../../hooks';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { menuPaths } from '../../menu';
import { Lesson, LessonsResp } from './Ilesson';
import LessonOnlineBadge from './LessonOnlineBadge';

const Upcoming = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<Lesson[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const apiCallHook = useAPICall();

  const loadLessons = () => {
    setIsLoading(true);
    if (!isLoading) {
      apiCallHook.run(
        APILessons,
        'POST',
        (l: LessonsResp) => {
          setIsLoading(false);
          setLessons(l.lessons);
        },
        { finished: false },
        undefined,
        () => setIsLoading(false)
      );
    }
  };

  useEffectOnce(loadLessons);

  const sortedGroupLessons: any = sortGroupLessons(lessons);

  return (
    <PageWrapper title={t('menu.upcoming')}>
      <Page>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row pb-5'>
              <div className='h1 col-lg-6 col-xs-12'>
                <span>
                  <Icon icon='moreTime' className='me-2'></Icon>
                  {t('menu.upcoming')}
                </span>
              </div>
              <div className='col-lg-6 col-xs-12 d-flex justify-content-lg-end'>
                <Button
                  color='primary'
                  icon='moreTime'
                  size={'lg'}
                  onClick={() => navigate(`/${menuPaths.lesson}/new`)}
                >
                  {t('lessons.new.button')}
                </Button>
              </div>
            </div>
            {isLoading ? (
              <div className='text-center'>
                <div className='spinner-border text-primary' role='status'></div>
              </div>
            ) : null}

            {Object.keys(sortedGroupLessons).length > 0 && (
              <>
                <h3 className='pb-1 d-flex justify-content-between'>
                  <span>
                    <Icon icon='AccessTime' className='me-2'></Icon>
                    {t('upcoming.upcomingLessons')}
                  </span>
                </h3>
                <Accordion
                  id={'lesson-stages'}
                  shadow={'lg'}
                  isFlush={false}
                  color={'primary'}
                  activeItemId={Object.keys(sortedGroupLessons)[0]}
                >
                  {Object.keys(sortedGroupLessons)?.map((key: string, idx) => {
                    const monthOfLessons: Lesson[] = sortedGroupLessons[key];
                    const title = `${new Date(new Date().setMonth(+key.split('-')[1] - 1)).toLocaleString('sk', {
                      month: 'long'
                    })} ${key.split('-')[0]}`;
                    return (
                      <AccordionItem key={idx} id={key} title={title} headerTag={'h1'}>
                        {monthOfLessons?.map((lesson: Lesson, lidx) => (
                          <Card className={`shadow-3d-${lesson.status_class}`} shadow={'none'} key={lidx}>
                            <CardHeader>
                              <CardLabel icon='AccessTime' className={`full-width icon-${lesson.status_class}`}>
                                <CardTitle
                                  tag='h5'
                                  className='h5 d-flex align-items-center justify-content-between'
                                  style={{ lineHeight: '1.5em' }}
                                >
                                  <div>
                                    <span>{lesson.datetime}</span>
                                    <span
                                      className={`border border-${lesson.status_class} h6 border-2 ms-3 text-${lesson.status_class} fw-bold px-2 py-1 rounded nowrap`}
                                    >
                                      {t(`lessons.status.${lesson.status}`)}
                                    </span>
                                    {Boolean(lesson?.online) && <LessonOnlineBadge />}
                                  </div>
                                </CardTitle>
                              </CardLabel>
                            </CardHeader>
                          </Card>
                        ))}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Upcoming;
